import { NotificationServices } from 'types'
import API, { NOTIFICATION, headers } from 'api'
import { errors, api } from 'services/index'
import { transformCsvResponse } from 'utils'

const promiseToJson = async (promise: Response) => {
  try {
    return await promise.json()
  } catch {
    return {}
  }
}

const response = async (promise: Response, key: string = null) => {
  const res = await promiseToJson(promise)
  if (key) {
    return {
      ...res,
      status: promise.status,
      response: errors.notify(key[promise.status], promise.status),
    }
  }
  return { ...res, status: promise.status, response: null }
}

const notification: NotificationServices = {
  collection: async ({ page = '1', itemsPerPage = '30', order, searchingWord }) => {
    const url = new URL(API(NOTIFICATION.DEFAULT))
    const params = [
      ['page', page.toString()],
      ['itemsPerPage', itemsPerPage.toString()],
      ['name', searchingWord],
      ['name', searchingWord],
      [order?.type, order?.value],
    ]
    url.search = new URLSearchParams(params).toString()
    const promise = await fetch(url.href, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.notification.collection)
  },
  once: async ({ id }) => {
    const promise = await fetch(`${API(NOTIFICATION.DEFAULT)}/${id}`, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.notification.once)
  },
  create: async (body) => {
    const promise = await fetch(`${API(NOTIFICATION.DEFAULT)}`, {
      method: 'POST',
      body,
      headers: headers('file', true),
    })
    return response(promise, api.notification.create)
  },
  update: async ({ id }, body) => {
    const promise = await fetch(`${API(NOTIFICATION.DEFAULT)}/${id}`, {
      method: 'POST',
      body,
      headers: headers('file', true),
    })
    return response(promise, api.notification.update)
  },
  remove: async ({ id }) => {
    const promise = await fetch(`${API(NOTIFICATION.DEFAULT)}/${id}`, {
      method: 'DELETE',
      headers: headers(),
    })
    return response(promise, api.notification.remove)
  },
  enable: async ({ id }) => {
    const promise = await fetch(`${API(NOTIFICATION.DEFAULT)}/${id}/enable`, {
      method: 'PATCH',
      headers: headers(),
    })
    return response(promise, api.notification.enable)
  },
  disable: async ({ id }) => {
    const promise = await fetch(`${API(NOTIFICATION.DEFAULT)}/${id}/disable`, {
      method: 'PATCH',
      headers: headers(),
    })
    return response(promise, api.notification.disable)
  },
  customers: async ({ id }) => {
    const promise = await fetch(`${API(NOTIFICATION.DEFAULT)}/${id}/customers`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({}),
    })
    return transformCsvResponse(promise, api.notification.customers)
  },
  export: async () => {
    const promise = await fetch(API(NOTIFICATION.EXPORT), {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({}),
    })
    return transformCsvResponse(promise, api.notification.export)
  },
}

export default notification
